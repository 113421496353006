import React, { useState, useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import '../styles/Dashboard.css';
import { getStatistics } from '../apiService';

const Dashboard = () => {
  const [stats, setStats] = useState({
    totalTicketsSold: '',
    totalTicketsBooked: '',
    totalRevenue: '',
    latestOrders: [],
    orderRevenue: []
  });
  const chartRef = useRef(null);

  useEffect(() => {
    fetchStatistics();
  }, []);

  const fetchStatistics = async () => {
    try {
      const response = await getStatistics();
      if (response && response.data && response.data.success) {
        setStats(response.data.data);
      } else {
        console.error('API call successful but returned an error:', response?.data?.message);
      }
    } catch (error) {
      console.error('API call failed:', error);
    }
  };
  

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.destroy();
    }
    const ctx = document.getElementById('revenueChart').getContext('2d');
    const chartData = {
      labels: stats.orderRevenue.map(order => `${order.user_name}: ${order.reservation_date}`),
      datasets: [{
        label: 'Revenue',
        data: stats.orderRevenue.map(order => order.amount),
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1
      }]
    };
    chartRef.current = new Chart(ctx, {
      type: 'bar',
      data: chartData,
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [stats.orderRevenue]);

  return (
    <div className="dashboard-container">
      <div className="summary-tiles">
        <div className="tile">
          <span className="icon">🎫</span>
          <span className="value">{stats.totalTicketsSold}</span>
          <span className="label">Tickets Sold</span>
        </div>

        <div className="tile">
          <span className="icon">💰</span>
          <span className="value">{stats.totalRevenue}</span>
          <span className="label">Total Revenue</span>
        </div>
      </div>
      <div className="latest-orders">
        <h2>Latest Orders</h2>
        <table>
          <thead>
            <tr>
              <th>User</th>
              <th>Date</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {stats.latestOrders.map(order => (
              <tr key={order.reservation_id}>
                <td>{`${order.user_name} ${order.user_last_name}`}</td>
                <td>{order.reservation_date}</td>
                <td>{order.reservation_price}€</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="charts">
        <canvas id="revenueChart"></canvas>
      </div>
    </div>
  );
};

export default Dashboard;
