import React, { useEffect, useState } from 'react';
import { getAllReservations, deleteReservation, patchReservation, getOwnerById } from '../apiService';
import '../styles/ReservationsList.css';

const ReservationsList = () => {
  const [reservations, setReservations] = useState([]);
  const [editingReservationId, setEditingReservationId] = useState(null);
  const [editFormData, setEditFormData] = useState({
    ownerName: '',
    reservationDate: '',
    expirationDate: '',
    price: '',
    fullTicket: '',
    reducedTicket: '',
    freeTicket: ''
  });

  useEffect(() => {
    const fetchReservationsWithOwnerNames = async () => {
      try {
        const response = await getAllReservations();
        const reservationsWithOwners = await Promise.all(response.data.map(async (reservation) => {
          const ownerResponse = await getOwnerById(reservation.reservation_owner_id);
          const ownerName = ownerResponse.data.user_name || 'Unknown';
          return { ...reservation, ownerName };
        }));
        setReservations(reservationsWithOwners);
      } catch (error) {
        console.error('Failed to fetch reservations:', error);
      }
    };

    fetchReservationsWithOwnerNames();
  }, []);

  const startEdit = (reservation) => {
    setEditingReservationId(reservation.reservation_id);
    setEditFormData({
      ownerName: reservation.ownerName,
      reservationDate: reservation.reservation_date,
      expirationDate: reservation.reservation_expiration,
      price: reservation.reservation_price ? reservation.reservation_price.toString() : '',
      fullTicket: reservation.resa_tarif_full ? reservation.resa_tarif_full.toString() : '',
      reducedTicket: reservation.resa_tarif_handicap ? reservation.resa_tarif_handicap.toString() : '',
      freeTicket: reservation.resa_tarif_young ? reservation.resa_tarif_young.toString() : ''
    });
  };
  

  const handleEditFormChange = (event) => {
    const { name, value } = event.target;
    setEditFormData({ ...editFormData, [name]: value });
  };
  const handleDelete = async (reservationId) => {
    try {
      await deleteReservation(reservationId);
      const updatedReservations = reservations.filter(reservation => reservation.reservation_id !== reservationId);
      setReservations(updatedReservations);
    } catch (error) {
      console.error('Failed to delete the reservation:', error);
    }
  };
  

  const handleEditFormSubmit = async (event) => {
    event.preventDefault();
    const updatedReservation = {
      ...editFormData,
      reservation_price: parseFloat(editFormData.price),
      resa_tarif_full: parseInt(editFormData.fullTicket),
      resa_tarif_handicap: parseInt(editFormData.reducedTicket),
      resa_tarif_young: parseInt(editFormData.freeTicket),
    };

    try {
      await patchReservation(editingReservationId, updatedReservation);
      const updatedReservations = reservations.map((res) => (
        res.reservation_id === editingReservationId ? { ...res, ...updatedReservation } : res
      ));
      setReservations(updatedReservations);
      setEditingReservationId(null);
    } catch (error) {
      console.error('Failed to update the reservation:', error);
    }
  };

  const handleCancelEdit = () => {
    setEditingReservationId(null);
  };

  return (
    <div className="content-container">

      {editingReservationId ? (
        <form onSubmit={handleEditFormSubmit} className="edit-form">
          <label htmlFor="ownerName">Owner Name:</label>
          <input
            id="ownerName"
            name="ownerName"
            type="text"
            value={editFormData.ownerName}
            onChange={handleEditFormChange}
          />
          <label htmlFor="reservationDate">Reservation Date:</label>
          <input
            id="reservationDate"
            name="reservationDate"
            type="date"
            value={editFormData.reservationDate}
            onChange={handleEditFormChange}
          />
          <label htmlFor="expirationDate">Expiration Date:</label>
          <input
            id="expirationDate"
            name="expirationDate"
            type="date"
            value={editFormData.expirationDate}
            onChange={handleEditFormChange}
          />
          <label htmlFor="price">Price:</label>
          <input
            id="price"
            name="price"
            type="number"
            value={editFormData.price}
            onChange={handleEditFormChange}
          />
          <label htmlFor="fullTicket">Full Ticket:</label>
          <input
            id="fullTicket"
            name="fullTicket"
            type="number"
            value={editFormData.fullTicket}
            onChange={handleEditFormChange}
          />
          <label htmlFor="reducedTicket">Reduced Ticket:</label>
          <input
            id="reducedTicket"
            name="reducedTicket"
            type="number"
            value={editFormData.reducedTicket}
            onChange={handleEditFormChange}
          />
          <label htmlFor="freeTicket">Free Ticket:</label>
          <input
            id="freeTicket"
            name="freeTicket"
            type="number"
            value={editFormData.freeTicket}
            onChange={handleEditFormChange}
          />
          <br />

          <button type="submit" className="save-btn">Save Changes</button>
          <button type="button" onClick={handleCancelEdit} className="cancel-btn">Cancel</button>
        </form>
      ) : (
        <table className="reservations-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Owner Name</th>
              <th>Reservation Date</th>
              <th>Expiration Date</th>
              <th>Price</th>
              <th>Full Tickets</th>
              <th>Reduced Tickets</th>
              <th>Free Tickets</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {reservations.map(reservation => (
              <tr key={reservation.reservation_id}>
                <td>{reservation.reservation_id}</td>
                <td>{reservation.ownerName}</td>
                <td>{reservation.reservation_date}</td>
                <td>{reservation.reservation_expiration}</td>
                <td>{reservation.reservation_price}</td>
                <td>{reservation.resa_tarif_full}</td>
                <td>{reservation.resa_tarif_handicap}</td>
                <td>{reservation.resa_tarif_young}</td>
                <td>
                  <button type="button" onClick={() => startEdit(reservation)}>Edit</button>
                  <button type="button" onClick={() => handleDelete(reservation.reservation_id)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ReservationsList;
