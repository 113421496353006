import React, { useState } from 'react';
import '../styles/Loginpage.css';


function authenticate(username, password) {
    return username === "admin" && password === "root";
}

function LoginPage({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const isValidUser = authenticate(username, password);
    if (isValidUser) {
      setError(false);
      onLogin(true);
    } else {
      setError(true);
      onLogin(false);
    }
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleSubmit}>
        {error && (
          <p className="login-error-message">Identifiant ou mot de passe incorrect.</p>
        )}
        <label>
          Identifiant:
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </label>
        <label>
          Mot de passe:
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>
        <button type="submit">Se connecter</button>
      </form>
    </div>
  );
}

export default LoginPage;