// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import ReservationsList from './pages/ReservationsList';
import Dashboard from './pages/Dashboard';
import LoginPage from './pages/Loginpage';
import PrivateRoute from './Components/PrivateRoute';
import './App.css';
import { useNavigate } from 'react-router-dom';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const authStatus = localStorage.getItem('isAuthenticated') === 'true';
    setIsAuthenticated(authStatus);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('isAuthenticated');
    setIsAuthenticated(false);
  };

  const LogoutButton = () => {
    let navigate = useNavigate();

    useEffect(() => {
      if (!isAuthenticated) {
        navigate('/login');
      }
    }, [isAuthenticated, navigate]);

    return (
      <button onClick={handleLogout}  className="logout-button" style={{ cursor: 'pointer' }}>
        Déconnexion
      </button>
    );
  };

  return (
    <Router>
      <div className="app-container">
        {isAuthenticated ? (
          <>
            <nav className="menu">
              <ul>
                <li><Link to="/">Dashboard</Link></li>
                <li><Link to="/reservations">Reservations</Link></li>
                <li>
                  <LogoutButton /> {/* Utilisez le nouveau composant LogoutButton ici */}
                </li>
              </ul>
            </nav>
            <main className="content">
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/reservations" element={<ReservationsList />} />
                {/* Autres routes protégées ici */}
              </Routes>
            </main>
          </>
        ) : (
          <Routes>
            <Route path="/login" element={<LoginPage onLogin={setIsAuthenticated} />} />
            <Route path="*" element={<Navigate replace to="/login" />} />
          </Routes>
        )}
      </div>
    </Router>
  );
};

export default App;
