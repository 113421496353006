import axios from 'axios';

const API_BASE_URL = 'https://api.expo-artemisia.site';

export const getLastReservation = () => {
  return axios.get(`${API_BASE_URL}?action=get-last-reservation`);
};



export const getStatistics = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}?action=get-statistics`);
    return response;
  } catch (error) {
    console.error('There was an error fetching the statistics:', error);
    throw error;
  }
};


export const getAllReservations = () => {
  return axios.get(`${API_BASE_URL}?action=get-all-reservations`);
};

export const getOwnerById = (ownerId) => {
    return axios.get(`${API_BASE_URL}?action=get-owner-by-id&id=${ownerId}`);
};

export const deleteReservation = (reservationId) => {
  return axios.delete(`${API_BASE_URL}?action=delete-reservation&id=${reservationId}`);
};


export const patchReservation = (reservationId, reservationData) => {
  return axios.patch(`${API_BASE_URL}?action=update-reservation&id=${reservationId}`, reservationData);
};